export function loadPDf(data, contentType, name) {
	const tUrl = window.URL.createObjectURL(new Blob([data], { type: contentType }))
	const tmp1 = document.createElement('a')
	tmp1.href = tUrl
	tmp1.target = '_blank'
	tmp1.download = `${name}.${contentType.split('/').pop()}`
	document.body.appendChild(tmp1)
	tmp1.click()
	URL.revokeObjectURL(tUrl)
	tmp1.remove()
}

export function numberFormaters(num) {
	return new Intl.NumberFormat('ru-RU').format(num)
}
